
import { defineComponent } from "vue-demi";
import InfoPolicyPayment from "@/components/info/PolicyPayment.vue";

export default defineComponent({
  name: "InfoPolicyPaymentView",
  components: {
    InfoPolicyPayment,
  },
});
